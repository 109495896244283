<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import moment from "moment";
import ServiceTicketModal from "@/components/modals/serviceTickets/modalViewServiceTicket.vue";
import EditServiceTicket from '@/components/modals/serviceTickets/modalEditServiceTicket.vue';
import StatusServiceTicket from '@/components/modals/serviceTickets/modalStatusServiceTicket.vue';
import DatePicker from "vue2-datepicker";
import dayjs from 'dayjs';
import ServiceTicketService from "@/services/ServiceTickets";
import AuthService from "@/services/Auth";
import {sanitizeHtml} from "bootstrap/js/src/util/sanitizer";




/**
 * Service Tickets component
 */
export default {
  components: { Layout, PageHeader, ServiceTicketModal,EditServiceTicket,StatusServiceTicket,DatePicker },
  page: {
    title: "Service Tickets",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    const today = dayjs();
    const sevenDaysAgo = dayjs().subtract(7, "day");
   
    return {
      title: "Service Tickets",
      items: [
        {
          text: "Service Tickets",
          active: true,
        },
      ],
      filterInput: {
        from_date: sevenDaysAgo.format("YYYY-MM-DD"), // Default: 7 days ago
        to_date: today.format("YYYY-MM-DD"), // Default: Today
        status: '',
        city:''
      },
      
      cityData:[],
      statusOptionsFilter: [
        { value: 0, text: '0-Сервис тикетот е одбиен' },
        { value: 1, text: '1-Нов сервис тикет' },
        { value: 2, text: '2-Сервис тикетот е отворен' },
        { value: 3, text: '3-Уредот е подигнат за сервис' },
        { value: 4, text: '4-Уредот се сервисира' },
        { value: 5, text: '5-Уредот е сервисиран' },
        { value: 6, text: '6-Уредот чека на испорака' },
        { value: 7, text: '7-Уредот е доставен' }
      ],
      isBusy: false,
      showDateInfo: true,
      tableData: [],
      totalRows: 1,
      logoSrc: require('@/assets/images/riversoft_logo.png'),
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "created_date",
      sortDesc: true,
      fields: [
        {key: "ticket_id", label: "Ticket ID", class: ["text-dark", "fw-bold"]},
        {key: "first_name", label: "First Name"},
        {key: "last_name", label: "Last Name"},
        {key: "company_name", label: "Company Name"},
        {key: "city", label: "City"},
        {key: "phone", label: "Phone"},
        {key: "product_model", label: "Product Model"},
        {key: "statusText", label: "Status", sortable: true},
        {key: "statusActions", label:""},
        {key: "created_date", label: "Date Created", sortable: true},
        { key: "actions", label: "Actions" },

      ],
      showModal: false,
      selectedTicketId: null,
      selectedTicketDetails: null,
    };
  },
  async created() {
    this.applyFilters();
  },
  mounted() {
    this.filterInput.from_date = '';
    this.filterInput.to_date = '';
    this.filterInput.status='';
    this.filterInput.city ='';
  },
  methods: {
    async getServiceTickets(filterString) {
      if (!filterString) {
        filterString = '';
      }
      try {
        const response = await ServiceTicketService.getServiceTickets(filterString);
        const cityResponse = await ServiceTicketService.getCity(filterString);
        this.cityData = cityResponse.data.data.map(city => ({
          value: city.name,
          text: city.name,
        }));

        const data = Array.isArray(response.data.data) ? response.data.data : []; // Ensure `data` is an array
        this.tableData = data.map((ticket) => ({
          ticket_id: ticket.id,
          first_name: ticket.first_name,
          last_name: ticket.last_name,
          guarantee: ticket.guarantee,
          company_name: ticket.company_name,
          phone: ticket.phone,
          email: ticket.email,
          city: ticket.city,
          address: ticket.address,
          product_model: ticket.product_model,
          product_serial: ticket.product_serial,
          fiscal_slip: ticket.fiscal_slip,
          guarantee_list_scan: ticket.guarantee_list_scan,
          description: ticket.description,
          status: ticket.status,
          statusText: this.getStatusText(ticket.status),
          created_date: ticket.created,
        }));

        this.totalRows = this.tableData.length;
      } catch (error) {
        console.error("Error fetching service tickets:", error);
        this.tableData = [];
        this.totalRows = 1;
      }
    },
    getStatusText(status) {
      const statusOptions = {
        0: '0-Сервис тикетот е одбиен',
        1: '1-Нов сервис тикет',
        2: '2-Сервис тикетот е отворен',
        3: '3-Уредот е подигнат за сервис',
        4: '4-Уредот се сервисира',
        5: '5-Уредот е сервисиран',
        6: '6-Уредот чека на испорака',
        7: '7-Уредот е доставен'
      };
      return statusOptions[status] || 'Unknown Status';

       
    },
    getStatusClass(status) {
    const statusClasses = {
      0: 'sivo',      
      1: 'crven',         // Red
      2: 'portokalov',    // Orange
      3: 'zolta',         // Yellow
      4: 'svetlo-zolta',  // Light Yellow
      5: 'svetlo-zeleno', // Light Green
      6: 'sredno-zeleno', // Medium Green
      7: 'zeleno'         // Green
    };
    return statusClasses[status] || ''; // Default to no class if status is not found
  },
    // applyFilter() {
    //   // this.getServiceTickets();  // Re-fetch with applied filters
    // },
    applyFilters() {
      const formattedFromDate = dayjs(this.filterInput.from_date).format("YYYY-MM-DD");
      const formattedToDate = dayjs(this.filterInput.to_date).format("YYYY-MM-DD");
      let filterString = '';

      if (this.filterInput.from_date) {
        filterString += `from_date=${formattedFromDate}&`;
      }

      if (this.filterInput.to_date) {
        filterString += `to_date=${formattedToDate}&`;
      }
      // alert(`Filter input status is ${this.filterInput.status}`);
      if (this.filterInput.status || this.filterInput.status === 0) {
        // filterString += `status=${this.filterInput.status}&`;
         filterString += `status=${this.filterInput.status}&`;
      }
        if (this.filterInput.city || this.filterInput.city === 0) {
         filterString += `city=${this.filterInput.city}&`;
      }


      if (filterString) {
        // Remove the last '&' character
        filterString = filterString.slice(0, -1);
      }
      // console.log(`Filter String: ${filterString}`);
      this.getServiceTickets(filterString);
    },
    openModal(ticket) {
      // console.log("Selected Ticket:", ticket);
      this.selectedTicketId = ticket.ticket_id;
      // console.log("Modal Ticket ID:", this.selectedTicketId);
      this.showModal = true;
      // console.log("Show Modal:", this.showModal);
    },
    openEditModal(ticket) {
      this.selectedTicketDetails = ticket;
      this.$bvModal.show('edit_service_ticket');
    },
    openStatusModal(ticket) {
      this.selectedTicketDetails = ticket;
      this.$bvModal.show('status_service_ticket');
    },
    closeModal() {
      this.showModal = false; // Close the modal
      this.selectedTicketId = null; // Clear the selected ticket
    },
    getFilters() {
      let filter_string = "&limit=5000";
      if (this.filterInput.from_date) {
        filter_string += `&from_date=${moment(this.filterInput.from_date).format("YYYY-MM-DD")}`;
      }
      if (this.filterInput.to_date) {
        filter_string += `&to_date=${moment(this.filterInput.to_date).format("YYYY-MM-DD")}`;
      }
     
      return filter_string.substring(1);
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    async mergeHistoryWithUsers(ticketHistory) {
      // First ensure we're working with an array
      const safeHistory = Array.isArray(ticketHistory) ? ticketHistory : [];

      if (safeHistory.length === 0) return [];

      // Fetch user details for each admin_id
      const userPromises = safeHistory.map(entry =>
          AuthService.getUsers(`id=${entry.admin_id}`).then(res => res.data)
      );

      const historyUsers = await Promise.all(userPromises);

      // Merge history with user details
      return safeHistory.map(historyItem => {
        const matchedUser = historyUsers.find(
            user => user?.users?.[0]?.id === historyItem.admin_id
        );
        return {
          ...historyItem,
          admin_username: matchedUser?.users?.[0]?.username || "Unknown"
        };
      });
    },
     generateHistoryContent(mergedHistory) {
  if (!mergedHistory || mergedHistory.length === 0) return '<p>No history available.</p>';

  // Generate HTML for each history entry
  return mergedHistory.map(history => `
    <p>
      ${history.timestamp || 'N/A'},
      ${history.admin_username || 'Unknown'}
      (${history.action || 'N/A'}),
      ${this.getStatusText(history.status) || 'Unknown'}
    </p>
  `).join('');
},
    async printDocument(item) {
      try {
        // Fetch ticket details
        const ticketResponse = await ServiceTicketService.getServiceTicket(item.ticket_id);
        const ticketData = ticketResponse.data;
        const ticketDetails = ticketData[0];

        // Fetch ticket history
        const historyResponse = await ServiceTicketService.getServiceTicketHistory(item.ticket_id);
        const ticketHistory = historyResponse.data.data;

        // Merge history with user details
        const mergedHistory = await this.mergeHistoryWithUsers(ticketHistory);

        // Generate history content
        const historyContent = this.generateHistoryContent(mergedHistory);

        // Generate printable content
        const printableContent = this.generatePrintableContent(ticketDetails, historyContent);

        // Create an iframe for printing
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        iframe.style.left = '-9999px'; // Move off-screen
        document.body.appendChild(iframe);

        iframe.contentDocument.write(printableContent);
        iframe.contentDocument.close();

        iframe.contentWindow.onload = () => {
          iframe.contentWindow.print();
          setTimeout(() => document.body.removeChild(iframe), 100);
        };

      } catch (error) {
        console.error("Error fetching ticket details or history:", error);
      }
    },
     generatePrintableContent(ticketDetails, historyContent) {
      const logoSrc = this.logoSrc;
      const statusText = this.getStatusText(ticketDetails.status) || 'N/A';

       return `
          <!DOCTYPE html>
<html>
<head>
    <title></title>
    <style>
        body {
            font-family: Arial, sans-serif;
            text-align: center;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
        }
        img {
            margin-bottom: 20px;
        }
        p {
            margin: 5px 0;
        }
        @page {
            size: auto;
            margin: 0mm;
        }
        .footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            padding: 20px 0;
            font-size: 14px;
            border-top: 1px solid #ccc;
            background: #3465a4 !important; /* Light blue color */
            width: 100%;
            -webkit-print-color-adjust: exact; /* Ensures background color prints in WebKit browsers */
            color-adjust: exact; /* Ensures background color prints in other browsers */
        }
        .signature-container {
            position: absolute;
            bottom: 60px; /* Ensures it stays above the footer */
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 20px;
        }
        .signature-box {
            border-bottom: 1px solid black;
            width: 40%;
            text-align: center;
            padding-bottom: 40px;
            margin-right: 30px;
        }
        @media print {
            .footer {
                position: fixed;
                bottom: 0;
                background: #3465a4 !important; /* Ensure background color is applied in print */
                color: white !important;
                display: block;
                -webkit-print-color-adjust: exact; /* Ensures background color prints in WebKit browsers */
                color-adjust: exact; /* Ensures background color prints in other browsers */
            }
            .signature-container {
                position: fixed;
                bottom: 80px;
            }
        }
    </style>
</head>
<body>
    <div id="printableArea" style="text-align: center; overflow: visible; position: relative; min-height: 100vh;">
        <div style="margin-top: 30px">
            <img src="${logoSrc}" alt="Logo" style="width: 300px; height: auto; margin-bottom: 20px;" />
        </div>
        <hr>
        <small style="text-align: center;">ул.Браќа Миладинови бр.18 2400 Струмица Северна Македонија; 034/340-020;servis@riversoft.mk, www.riversoft.mk</small>
        <h1 style="margin-top: 60px; padding: 1px 0; margin-bottom: 100px">ПОТРОШУВАЧКИ ПРИГОВОР</h1>
        <div style="text-align: left; margin-left: 25px">
            <p style="margin-bottom: 13px;"><strong>Датум:</strong> ${ticketDetails.created ? ticketDetails.created.split(' ')[0].split('-').reverse().join('-') : 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Име и презиме на купувач:</strong> ${sanitizeHtml(ticketDetails.first_name)} ${sanitizeHtml(ticketDetails.last_name) || 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Име на Фирма:</strong> ${sanitizeHtml(ticketDetails.company_name) || 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Адреса на живеење:</strong> ${sanitizeHtml(ticketDetails.address) || 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Град:</strong> ${sanitizeHtml(ticketDetails.city) || 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Телефонски број:</strong> ${sanitizeHtml(ticketDetails.phone) || 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Email:</strong> ${sanitizeHtml(ticketDetails.email) || 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Фискална сметка:</strong> ${ticketDetails.fiscal_slip ? '<span style="color:green;">✔</span>' : 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Гарнтен лист:</strong> ${ticketDetails.guarantee_list_scan ? '<span style="color:green;">✔</span>' : 'N/A'}</p>
            <p style="margin-bottom: 13px;"><strong>Модел на производот:</strong> ${sanitizeHtml(ticketDetails.product_model) || 'N/A'}</p>
            <p style="margin-bottom: 10px;"><strong>Сериски број на производот:</strong> ${sanitizeHtml(ticketDetails.product_serial) || 'N/A'}</p>
            <strong>Опис на приговорот:</strong>
            <p style="margin-bottom: 13px;">${sanitizeHtml(ticketDetails.description) || 'N/A'}</p>
        </div>
    </div>

    <!-- Signatures -->
    <div class="signature-container">
        <div class="signature-box">
            <small>Име, Презиме и потпис на купувачот</small>
        </div>
        <div class="signature-box">
            <small>ПЦ Хардвер Контрол ДООЕЛ</small>
        </div>
    </div>

    <!-- Footer -->
    <footer class="footer">
        Контакт Центар: 15-051
    </footer>
</body>
</html>
        `;

     },




    async handlePrintTickets(item) {
      this.printDocument(item);
    },

    resetFilters() {
      this.filterInput = {
        from_date: null,
        to_date: null,
        status: '',
        city:'',
      };
      this.getServiceTickets();
    },
      hideDateInfo(){
               this.showDateInfo = false;
      }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <!-- Filter Section -->
        <div class="card">
          <div class="card-body">
        <div class="filter-section mb-4">
          <b-form @submit.prevent="applyFilter">
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <b-form-group label="Start Date" label-for="formrow-from_date-input" class="mb-3">
                  <date-picker
                      v-model="filterInput.from_date"
                      append-to-body
                      lang="en"
                      confirm
                      format="YYYY-MM-DD"
                      @input="hideDateInfo"
                  ></date-picker>


                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-3">
                <b-form-group label="End Date" label-for="formrow-to_date-input" class="mb-3">
                  <date-picker
                      v-model="filterInput.to_date"
                      append-to-body
                      lang="en"
                      confirm
                      format="YYYY-MM-DD"
                      @input="hideDateInfo"
                  ></date-picker>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-3">
                <b-form-group label="Status" label-for="formrow-status-input" class="mb-3">
                  <b-form-select
                      v-model="filterInput.status"
                      :options="statusOptionsFilter"
                      class="form-control"
                      @input="hideDateInfo"
                  ></b-form-select>
                </b-form-group>
              </div>
                <div class="col-sm-12 col-md-3">
                <b-form-group label="City" label-for="formrow-city-input" class="mb-3">
                  <b-form-select
                      v-model="filterInput.city"
                      :options="cityData"
                      class="form-control"
                      @input="hideDateInfo"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="col d-inline-flex">
              <button type="button" class="btn btn-success me-2 w-lg" @click="applyFilters">Filter</button>
              <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
            </div>
<!--            <b-button  @click="applyFilters" type="submit" variant="primary" class="mt-2">-->
<!--              Apply Filter-->
<!--            </b-button>-->
<!--            <b-button variant="secondary" @click="resetFilters" class="mt-2 ml-2">-->
<!--              Reset Filters-->
<!--            </b-button>-->
          </b-form>
        </div>
        <p id="date_info" class="text-muted mt-3 mb-0 text-left" v-if="showDateInfo">
              <span class=" me-1">
                Service Tickets from the last 7 days
              </span>
        </p>
            </div>
          </div>

        <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center fw-normal">
                  Show&nbsp;
                  <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center fw-normal">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>

        <!-- Table Section -->
        <div class="table-responsive">
          <b-table
              table-class="table-responsive mb-0"
              :busy="isBusy"
              :items="tableData"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              show-empty
              empty-text="No Tickets Found"
          >
            <template #cell(statusText)="row">
              <!-- Dynamically apply class based on the ticket status -->
             
              <div  :class="getStatusClass(row.item.status)">
                <span>
                   {{ row.item.statusText }}
                </span>
              </div>
            </template>
            <template #cell(statusActions)="row">
              <!--              <b-button size="sm" @click="openModal(row.item)">View</b-button>-->
              <!--              <b-button size="sm" variant="warning" @click="openEditModal(row.item)">Edit</b-button>-->
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <a
                      href="javascript:void(0);"
                      @click="openStatusModal(row.item)"
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit Status"
                  >
                    <i class="uil uil-pen font-size-18"></i>
                  </a>
                </li>
              </ul>

            </template>
            <!-- Add a slot for the actions column -->
            <template #cell(actions)="row">
<!--              <b-button size="sm" @click="openModal(row.item)">View</b-button>-->
<!--              <b-button size="sm" variant="warning" @click="openEditModal(row.item)">Edit</b-button>-->
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <a
                      href="javascript:void(0);"
                      @click="openModal(row.item)"
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="View Order"
                  >
                    <i class="uil uil-eye font-size-18"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                      href="javascript:void(0);"
                      @click="openEditModal(row.item)"
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit Order"
                  >
                    <i class="uil uil-pen font-size-18"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                      href="javascript:void(0);"
                      @click="handlePrintTickets(row.item)"
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Print Tickets"
                  >
                    <i class="uil uil-print font-size-18"></i>
                  </a>
                </li>

              </ul>

            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <ServiceTicketModal
              :ticket_id="selectedTicketId"
              v-model="showModal"
          />
          <EditServiceTicket
              :ticketDetails="selectedTicketDetails"
              ref="editModal"
          />
          <StatusServiceTicket
              :ticketDetails="selectedTicketDetails"
          />

          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="my-0"
          ></b-pagination>
        </div>
      </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.custom-datepicker .bv-popover {
  font-size: 12px; /* Adjust font size */
  width: 250px; /* Adjust the width of the dropdown */
  height: auto; /* Adjust height if necessary */
}

.custom-datepicker .bv-calendar {
  font-size: 14px; /* Change the font size inside the calendar */
}

.custom-datepicker .bv-calendar td {
  padding: 5px; /* Adjust padding between the days */
}

.custom-datepicker .bv-popover-arrow {
  display: none; /* Optionally hide the arrow */
}

</style>